body {
  background-image: url("../../../public/formBackground.jpg");
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}

.changeDropdown {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 0 20px;
  }
  .dropdown, .dropup {
    position: relative;
  }
  button.dropbtn {
    background: #f1f1f1;
    color: #000;
    padding: 0.5em 1em;
    border-radius: 5px;
    gap: 5px;
  }
  .dropbtn {
    color: #333;
    padding: 8px 25px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    text-align: left;
    align-items: center;
  }
  .dropdown-content {
    position: absolute;
    background-color: #f1f1f1;
    min-width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    top: 35px;
    border-radius: 0px 0px 5px 5px;
  }
  .dropdown-content div {
    color: black;
    width: 100%;
    padding: 12px 10px;
    font-size: 14px !important;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }

  .logoCss {
    width: 100px;
    height: 40px;
  }

  .fontStyle {
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .IndividuallayoutContainer { 
    width: 100%; 
    background-color: rgba(39, 74, 139, 0.8);
    height: 100vh;
  }

  .CopratelayoutContainer { 
    width: 100%; 
    background-color: rgba(39, 74, 139, 0.8);
    height: auto;
    min-height: 100vh;
  }
  
  .formContainer { 
    max-Width: 1140px; 
    width: 100%;
    height: auto; 
    background-color: white; 
    margin: 34px auto;
    min-height: 766px;
  }

  .flag {
    width: 28px !important;
    height: 20px !important;
  }

  .formBody {
    overflow: hidden;
  }

  @media screen and (min-width: 1400px) {
    .formBody {
      overflow: visible;
    }
  }

  .css-1nmdiq5-menu {
    z-index: 99 !important;
  }

  @media screen and (max-width: 1024px) {
    .IndividuallayoutContainer { 
      width: 100%; 
      background-color: rgba(39, 74, 139, 0.8);
      height: auto;
    }
  }