.successWrapper {
    background-color: #2752a073;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: 9;
    left: 0;
}

.successCard {
    width: 500px;
    height: 250px;
    background-color: white;
    border-radius: 12px;
}